const messages = {
  en: {
    welcomeMessage: 'Welcome to our website!',
    dateFormat: 'MM/DD/YYYY',
    aboutUs: 'About us',
    simpleSolution: 'The simplest solution for you',
    appsAndSoftware: 'Apps and Software for your idea, no matter what it is.',
    softwareArchitecture: 'Software Architecture',
    designCreativity: 'Design & Creativity',
    customCreation: 'Custom Creation',
    bestArchitecture: 'We think of the best architecture for your project, and execute with best practices!',
    bestDesign: 'We work with the best design for your business look.',
    simpleOrRobustApps: 'We develop simple or robust applications to meet your needs.',
    simoniniSoftware: 'At Simonini Software, we see technology as a powerful tool to bring innovative ideas to life. We are a Venture Builder company, which means we design, develop, and cultivate startups that have some type of software as their operational core, whether it is a mobile app, a web system, or an e-commerce solution.',
    creativityAndSimplicity: 'We firmly believe in the power of creativity and simplicity as the foundation for functional and effective solutions. Our commitment to innovation drives us to prioritize the creation of concepts that truly matter and can make a difference in the business world.',
    moreThanSoftware: 'Here, you get more than just Software development - you get a business partner truly committed to helping you achieve your complete goal. Together, we can build a smarter, more efficient, and brighter future.',
    howCanWeHelp: 'How can we help you?',
    servicesProvided: 'Services provided for you.',
    ourProjects: 'Our Projects',
    someOfOurWork: 'Some of our Relevant Works',
    developedForBigNames: 'WE HAVE DEVELOPED FOR BIG NAMES IN THE GLOBAL MARKET',
    clients: 'CLIENTS',
    mobileAppsTitle: 'Mobile Apps',
    mobileAppsDesc: 'We work with all mobile platforms, whether native or hybrid, such as Flutter, Ionic, React Native, Objective-C, Swift, Kotlin.',
    webSystemsTitle: 'Web Systems',
    webSystemsDesc: 'Every application needs an administrative area; we create integrated apps with web modules, APIs, and general web modules.',
    startupConsultingTitle: 'Startup Consulting',
    startupConsultingDesc: 'We incorporate Mobile or Web technology into your company’s daily operations, tailored to the type of business and the needs of your consumers.',
    devAllocationTitle: 'Developer Allocation',
    devAllocationDesc: 'The best developers in the market, matched to the needs of each project. Ongoing support during the allocation period.',
    maintenanceEvolutionTitle: 'Maintenance and Evolution',
    maintenanceEvolutionDesc: 'Perform corrective maintenance and evolve applications and systems to ensure uninterrupted and high-quality operation.',
    aiIntegratedSolutionsTitle: 'AI Integrated Solutions',
    aiIntegratedSolutionsDesc: 'We integrate AI into the solutions we create and develop custom artificial intelligences for automation and advanced analysis in each area.',
    comingSoon: 'Coming soon!',
    currentlyDown: 'Currently down',
    release: 'Release',
    becomePartner: 'Looking to start a business? We can be your partner!',
    contactUs: 'Contact us',
    developerArea: 'Developer Area',
    jobVacancies: 'Job Vacancies',
    company: 'Company',
    services: 'Services',
    contact: 'Contact',
    privacyPolicy: 'Privacy Policy',
    addressBrazil: '650 Paulistânia Street. ZIP Code: 05440-001 | São Paulo - SP - Brazil.',
    addressPortugal: '14C Castilho Street, 5th Floor, 1250-069 Lisbon - Portugal.',
    shortlyAddressBrazil: '650 Paulistânia Street, SP - Brazil',
    shortlyAddressPortugal: '14C Castilho Street, Lisbon - Portugal',
    contactAddresses: 'Contact Addresses',
    phones: 'Phones',
    emails: 'Emails',
    offices: 'Offices',
    letsTalk: 'Let\'s Talk',
    yourName: 'Your Name',
    yourEmail: 'Your Email',
    subject: 'Subject',
    yourMessage: 'Your Message',
    send: 'Send',
    home: 'Home',
  },
  pt: {
    welcomeMessage: 'Bem-vindo ao nosso site!',
    dateFormat: 'DD/MM/YYYY',
    aboutUs: 'Sobre nós',
    simpleSolution: 'A solução mais simples para você',
    appsAndSoftware: 'Apps e Softwares para a sua ideia, não importa qual seja.',
    softwareArchitecture: 'Arquitetura de Software',
    designCreativity: 'Design & Criatividade',
    customCreation: 'Criação Sob Medida',
    bestArchitecture: 'Pensamos na melhor arquitetura para o seu projeto, e executamos com as melhores práticas!',
    bestDesign: 'Trabalhamos com o melhor design para o visual do seu negócio.',
    simpleOrRobustApps: 'Desenvolvemos aplicações simples ou robustas para atender a sua necessidade.',
    simoniniSoftware: 'Na Simonini Software, enxergamos a tecnologia como uma ferramenta poderosa para dar vida a ideias inovadoras. Somos uma empresa do tipo "Venture Builder", ou seja, concebemos, desenvolvemos e cultivamos startups que possuem como núcleo operacional algum tipo de software, seja ele um aplicativo móvel, um sistema web ou uma solução de e-commerce.',
    creativityAndSimplicity: 'Acreditamos firmemente na força da criatividade e na simplicidade como base para soluções funcionais e eficazes. Nosso compromisso com a inovação nos leva a priorizar a criação de conceitos que realmente importam e podem fazer a diferença no mundo dos negócios.',
    moreThanSoftware: 'Aqui, você obtém mais do que apenas desenvolvimento de Software - você obtém um parceiro de negócios que está verdadeiramente comprometido em ajudá-lo a alcançar seu objetivo completo. Juntos, podemos construir um futuro mais inteligente, mais eficiente e mais brilhante.',
    howCanWeHelp: 'Em que podemos lhe ajudar?',
    servicesProvided: 'Serviços fornecidos para você.',
    ourProjects: 'Nossos projetos',
    someOfOurWork: 'Alguns de nossos Trabalhos Relevantes',
    developedForBigNames: 'JÁ DESENVOLVEMOS PARA GRANDES NOMES DO MERCADO GLOBAL',
    clients: 'CLIENTES',
    mobileAppsTitle: 'Aplicativos Mobile',
    mobileAppsDesc: 'Atuamos com todas as plataformas de Aplicativos, seja nativa ou híbrida. Tais como: Flutter, Ionic, React Native, Objective-C, Swift, Kotlin.',
    webSystemsTitle: 'Sistemas Web',
    webSystemsDesc: 'Todo Aplicativo tem a necessidade de uma Área Administrativa, fazemos Apps integrados com módulo Web. APIs e Módulos Web no geral.',
    startupConsultingTitle: 'Consultoria de Startups',
    startupConsultingDesc: 'Incorporamos a tecnologia Mobile ou Web no dia a dia da sua empresa. De acordo com cada tipo de negócio e a necessidade de seus consumidores.',
    devAllocationTitle: 'Alocação de Devs',
    devAllocationDesc: 'Os melhores desenvolvedores no mercado, de acordo com a necessidade de cada Projeto. Acompanhamento durante o período de alocação.',
    maintenanceEvolutionTitle: 'Manutenção e Evolução',
    maintenanceEvolutionDesc: 'Realizar as manutenções corretivas e evoluir os Aplicativos e Sistemas visando garantir a operação ininterrupta e de qualidade dos mesmos.',
    aiIntegratedSolutionsTitle: 'Soluções Integradas a IA',
    aiIntegratedSolutionsDesc: 'Integramos IA às soluções criadas e desenvolvemos inteligências artificiais customizadas, para automação e análises avançadas de cada área.',
    comingSoon: 'Em breve!',
    currentlyDown: 'Fora do ar',
    release: 'Lançamento',
    becomePartner: 'Quer empreender? Podemos ser Parceiros!',
    contactUs: 'Fale conosco',
    developerArea: 'Área do Desenvolvedor',
    jobVacancies: 'Vagas',
    company: 'Empresa',
    services: 'Serviços',
    contact: 'Contato',
    privacyPolicy: 'Política de Privacidade',
    addressBrazil: 'Rua Paulistânia, 650. CEP: 05440-001 | São Paulo - SP - Brasil.',
    shortlyAddressBrazil: 'Rua Paulistânia, 650, SP - Brasil',
    shortlyAddressPortugal: 'Rua Castilho 14C, Lisboa - Portugal',
    addressPortugal: 'Rua Castilho 14C 5th floor, 1250-069 Lisboa - Portugal.',
    contactAddresses: 'Endereços de Contato',
    phones: 'Telefones',
    emails: 'Emails',
    offices: 'Escritórios',
    letsTalk: 'Vamos conversar',
    yourName: 'Seu nome',
    yourEmail: 'Seu email',
    subject: 'Assunto',
    yourMessage: 'Sua mensagem',
    send: 'Enviar',
    home: 'Início',
  },
  pt_PT: {
    welcomeMessage: 'Bem-vindo ao nosso site!',
    dateFormat: 'DD/MM/YYYY',
    aboutUs: 'Sobre nós',
    simpleSolution: 'A solução mais simples para si',
    appsAndSoftware: 'Aplicações e Software para a sua ideia, não importa qual seja.',
    softwareArchitecture: 'Arquitetura de Software',
    designCreativity: 'Design & Criatividade',
    customCreation: 'Criação à Medida',
    bestArchitecture: 'Pensamos na melhor arquitetura para o seu projeto, e executamos com as melhores práticas!',
    bestDesign: 'Trabalhamos com o melhor design para o visual do seu negócio.',
    simpleOrRobustApps: 'Desenvolvemos aplicações simples ou robustas para atender às suas necessidades.',
    simoniniSoftware: 'Na Simonini Software, vemos a tecnologia como uma ferramenta poderosa para dar vida a ideias inovadoras. Somos uma empresa do tipo "Venture Builder", ou seja, concebemos, desenvolvemos e cultivamos startups que possuem como núcleo operacional algum tipo de software, seja ele uma aplicação móvel, um sistema web ou uma solução de e-commerce.',
    creativityAndSimplicity: 'Acreditamos firmemente na força da criatividade e na simplicidade como base para soluções funcionais e eficazes. O nosso compromisso com a inovação leva-nos a priorizar a criação de conceitos que realmente importam e podem fazer a diferença no mundo dos negócios.',
    moreThanSoftware: 'Aqui, obtém mais do que apenas desenvolvimento de Software - obtém um parceiro de negócios verdadeiramente comprometido em ajudá-lo a alcançar o seu objetivo completo. Juntos, podemos construir um futuro mais inteligente, mais eficiente e mais brilhante.',
    howCanWeHelp: 'Em que podemos ajudá-lo?',
    servicesProvided: 'Serviços fornecidos para si.',
    ourProjects: 'Os nossos projetos',
    someOfOurWork: 'Alguns dos nossos Trabalhos Relevantes',
    developedForBigNames: 'JÁ DESENVOLVEMOS PARA GRANDES NOMES DO MERCADO GLOBAL',
    clients: 'CLIENTES',
    mobileAppsTitle: 'Aplicações Móveis',
    mobileAppsDesc: 'Trabalhamos com todas as plataformas móveis, sejam nativas ou híbridas, como Flutter, Ionic, React Native, Objective-C, Swift, Kotlin.',
    webSystemsTitle: 'Sistemas Web',
    webSystemsDesc: 'Cada aplicação precisa de uma área administrativa; criamos apps integradas com módulos web, APIs e módulos web em geral.',
    startupConsultingTitle: 'Consultoria de Startups',
    startupConsultingDesc: 'Incorporamos tecnologia Mobile ou Web no dia a dia da sua empresa, adaptada ao tipo de negócio e às necessidades dos seus consumidores.',
    devAllocationTitle: 'Alocação de Desenvolvedores',
    devAllocationDesc: 'Os melhores desenvolvedores do mercado, ajustados às necessidades de cada projeto. Acompanhamento contínuo durante o período de alocação.',
    maintenanceEvolutionTitle: 'Manutenção e Evolução',
    maintenanceEvolutionDesc: 'Realizamos manutenções corretivas e evoluímos aplicações e sistemas para garantir operação ininterrupta e de alta qualidade.',
    aiIntegratedSolutionsTitle: 'Soluções Integradas com IA',
    aiIntegratedSolutionsDesc: 'Integramos IA nas soluções que criamos e desenvolvemos inteligências artificiais personalizadas para automação e análises avançadas em cada área.',
    comingSoon: 'Em breve!',
    currentlyDown: 'Atualmente indisponível',
    release: 'Lançamento',
    becomePartner: 'Quer empreender? Podemos ser parceiros!',
    contactUs: 'Fale connosco',
    developerArea: 'Área do Desenvolvedor',
    jobVacancies: 'Vagas',
    company: 'Empresa',
    services: 'Serviços',
    contact: 'Contacto',
    privacyPolicy: 'Política de Privacidade',
    addressBrazil: 'Rua Paulistânia, 650. CEP: 05440-001 | São Paulo - SP - Brasil.',
    addressPortugal: 'Rua Castilho 14C 5th floor, 1250-069 Lisboa - Portugal.',
    shortlyAddressBrazil: 'Rua Paulistânia, 650, SP - Brasil',
    shortlyAddressPortugal: 'Rua Castilho 14C, Lisboa - Portugal',
    contactAddresses: 'Endereços de Contacto',
    phones: 'Telefones',
    emails: 'Emails',
    offices: 'Escritórios',
    letsTalk: 'Vamos conversar',
    yourName: 'O seu nome',
    yourEmail: 'O seu email',
    subject: 'Assunto',
    yourMessage: 'A sua mensagem',
    send: 'Enviar',
    home: 'Início',
  }
};

export default messages;
